import React from 'react'
import { Link } from 'gatsby'

// Helpers
import removeDomaine from '../../../helpers/url.helpers'

const NavCategory = ({ oNavCategory }) => {
   return (
      <nav className="nav-services">
         <h3 className="background-line"><span>{oNavCategory.name}</span></h3>

         <ul className="list--unstyled">
            { oNavCategory.items.map((navItem, index) => (
               <li key={`navCategoryItem-${index}`}><Link to={removeDomaine(navItem.url)} activeClassName={`current-link-services`}>{navItem.title}</Link></li>
            ))}
         </ul>
      </nav>
   )
}

export default NavCategory