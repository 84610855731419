import React from 'react'
import Image from '../../image'

const HeroCategory = ({ oHero }) => {
   return (
      <div className="hero hero--category">
         <h1 className="container with-subtitle">
            {oHero.left.title}
            <span className="subtitle">{oHero.left.surtitle}</span>
         </h1>

         <div className="container hero--category__images">
            <div className="inner-box">
               {oHero.left.image && <Image imageID={oHero.left.image} /> }
            </div>

            <div className="inner-box">
               {oHero.right.image && <Image imageID={oHero.right.image} /> }
            </div>
         </div>
      </div>
   )
}

export default HeroCategory