import React from "react"
import { graphql } from "gatsby"
import Parse from 'html-react-parser'

import Layout from "../../components/layout"
import SEO from "../../components/seo"

// Components
import BlockHours from '../../components/elements/02-blocks/BlockHours'
import HeroCategory from '../../components/elements/10-hero/HeroCategory'
import NavCategory from '../../components/elements/19-nav/NavCategory'
import SectionMenu from '../../components/elements/03-sections/SectionMenu'
import SectionAboutLightbox from "../../components/elements/03-sections/SectionAboutLightbox"
import BlockHighlight from "../../components/elements/02-blocks/BlockHighlight";

const Page = ({ data }) => {

   let heroData = data.wordpressPage.acf.category_hero_category 

   let oHero = {
      left: {
         title: heroData.category_hero_category_left.category_hero_category_left_title,
         surtitle: heroData.category_hero_category_left.category_hero_category_left_surtitle,
         image: heroData.category_hero_category_left.category_hero_category_left_image
      },
      right: {
         image: heroData.category_hero_category_right.category_hero_category_right_image
      }
   }

   let oNavCategory = data.menus.edges[0].node

   // PARSING MENU
   let menu = data.wordpressPage.acf.category_menu

   let oSectionMenu = {
      title: menu.category_menu_title,
      menus: [],
      utils: []
   }


   if(menu.category_menu_sections) {
      menu.category_menu_sections.forEach(menuSection => {
         let tempMenu = {
            title: menuSection.category_menu_sections_title,
            items: []
         }

         if(menuSection.category_menu_sections_items) {
            menuSection.category_menu_sections_items.forEach(item => {
               let tempItem = {
                  title: item.category_menu_sections_items_title,
                  indicator: item.category_menu_sections_items_indicator,
                  price: item.category_menu_sections_items_price
               }

               tempMenu.items.push(tempItem)
            })
         }

         oSectionMenu.menus.push(tempMenu)
      })
   }

   if(menu.category_menu_utils) {
      menu.category_menu_utils.forEach(util => {
         oSectionMenu.utils.push(util.category_menu_utils_text)
      })
   }

   // PARSING SECTION ABOUT LIGHTBOX
   let sectionAboutData = data.wordpressPage.acf.category_two_columns_about_lightbox
   let oSectionAbout = {
      title: sectionAboutData.category_two_columns_about_lightbox_title,
      column_one: {
         text: sectionAboutData.category_two_columns_about_lightbox_column_one.category_two_columns_about_lightbox_column_one_text
      },
      column_two: {
         gallery: sectionAboutData.category_two_columns_about_lightbox_column_two.category_two_columns_about_lightbox_column_two_gallery
      }
   }

   // Parsing CTA
   let ctaData = data.wordpressPage.acf.category_cta
   let oBlockHighlight = {
      title: ctaData.category_cta_title,
      text: ctaData.category_cta_text
   }

   return (
      <Layout>
         <SEO WPPageID={data.wordpressPage.wordpress_id} />
         
         <div className="site page category category--traiteur">
            <BlockHours />

            <HeroCategory with oHero={oHero} />

            <main id="main" className="container container--category">
               <article>
                  {Parse(data.wordpressPage.content)}
               </article>
                  
               <aside>
                  <NavCategory oNavCategory={oNavCategory} />
               </aside>
               
               {oSectionMenu.title && <SectionMenu oSectionMenu={oSectionMenu} mTwoColumns={true} />}

            </main>

            { (oSectionAbout.column_one.text || oSectionAbout.column_two.gallery) && <SectionAboutLightbox oSectionAbout={oSectionAbout} mHalf={true} /> }

            <div className="container">
               <BlockHighlight oBlockHighlight={oBlockHighlight} />
            </div>
         </div>
      </Layout>
   )
}

export default Page

export const query = graphql`
   query($id: Int!) {
      wordpressPage(wordpress_id: { eq: $id }) {
         wordpress_id
         content
         title
         acf {
            category_cta {
               category_cta_text
               category_cta_title
            }
            category_hero_category {
               category_hero_category_left {
                  category_hero_category_left_image
                  category_hero_category_left_surtitle
                  category_hero_category_left_title
               }
               category_hero_category_right {
                  category_hero_category_right_image
               }
            }
            category_menu {
               category_menu_sections {
                  category_menu_sections_items {
                     category_menu_sections_items_indicator
                     category_menu_sections_items_price
                     category_menu_sections_items_title
                  }
                  category_menu_sections_title
               }
               category_menu_title
               category_menu_utils {
                  category_menu_utils_text
               }
            }
            category_two_columns_about_lightbox {
               category_two_columns_about_lightbox_title
               category_two_columns_about_lightbox_column_two {
                  category_two_columns_about_lightbox_column_two_gallery
               }
               category_two_columns_about_lightbox_column_one {
                  category_two_columns_about_lightbox_column_one_text
               }
            }
         }
      }
      menus: allWordpressWpApiMenusMenusItems(filter: {slug: {eq: "nos-services"}}) {
         edges {
            node {
               name
               items {
                  title
                  url
               }
            }
         }
      }
   }
`
